@use 'mixins/breakpoints' as *;
@use 'placeholders';
// reset
//
// Normalization of HTML elements, manually forked from Bootstrap 5 reboot which is
// in turn forked from Normalize.css to remove styles targeting irrelevant browsers
// while applying new styles.
//
// Note that we only reset common content elements
// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Scroll behavior smooth breaks scrolling on IOSv15.4 and above
// Root
// :root {
//   // font-size: $font-size-root;
//   @media (prefers-reduced-motion: no-preference) {
//     scroll-behavior: smooth;
//   }
// }

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.
body {
  // text-align: $body-text-align;
  background-color: var(--cipublic-background-color); // 2
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-primary);
  font-size: var(--cipublic-font-size-m);
  font-weight: var(--cipublic-font-weight-normal);
  line-height: var(--cipublic-line-height-xxl);
  margin: 0; // 1
  overflow-x: hidden;
  -webkit-tap-highlight-color: var(--cipublic-color-transparent); // 4
  text-size-adjust: 100%; // 3
  &.backdrop-active {
    overflow-y: hidden;
  }
}

// Content grouping
//
// 1. Reset Firefox's gray color
// 2. Set correct height and prevent the `size` attribute to make the `hr` look like an input field
hr {
  background-color: currentColor;
  border: 0;
  color: var(--cipublic-color-gray-300); // 1
  margin: var(--cipublic-size-xl) 0 0 0;
  // opacity: 0.25; // TODO: variable
}

hr:not([size]) {
  height: 1px; // 2
}

// Typography
// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p {
  @extend %p;
  margin-bottom: 0;
  margin-top: var(--cipublic-size-ml);
}

span {
  @extend %span;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  + * {
    margin-top: 0 !important;
  }
}

// Abbreviations
//
// 1. Duplicate behavior to the data-bs-* attribute for our tooltip plugin
// 2. Add the correct text decoration in Chrome, Edge, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Prevent the text-decoration to be skipped.
abbr[title] {
  cursor: help; // 3
  // 1
  text-decoration: underline dotted; // 2
  text-decoration-skip-ink: none; // 4
}

// Address
address {
  @extend %p;
  font-style: normal;
  margin-top: var(--cipublic-size-ml);
}

// Lists
ol,
ul {
  counter-reset: item;
  margin-bottom: 0;
  margin-top: var(--cipublic-size-ml);
  padding-left: var(--cipublic-size-l);

  li {
    @extend %p;
    position: relative;
  }

  > li {
    counter-increment: item;
    list-style: disc;
  }
}

// ul {
//   li {
//     table {
//       margin-top: var(--cipublic-size-s);
//     }
//   }
// }

ol > li {
  list-style: none;
  margin-top: var(--cipublic-font-size-s);
}

ul > li {
  margin-top: var(--cipublic-font-size-s);
}

> li::before {
  ol & {
    align-items: flex-end;
    content: counter(item) '.\a0\a0';
    display: flex;
    font-size: var(--cipublic-font-size-s);
    font-weight: var(--cipublic-font-weight-semibold);
    justify-content: flex-end;
    left: calc(var(--cipublic-size-l) * -1);
    line-height: var(--cipublic-line-height-l);
    margin-top: 0 !important;
    position: absolute;
    text-align: right;
    top: 0;
    width: var(--cipublic-size-l);

    @include media-breakpoint-up(sm) {
      font-size: var(--cipublic-font-size-m);
      line-height: var(--cipublic-line-height-xl);
    }
  }

  ol ol & {
    content: counter(item, lower-alpha) '.\a0\a0';
  }
  /* stylelint-disable-next-line selector-max-compound-selectors */
  ol ol ol & {
    content: counter(item, lower-roman) '.\a0\a0';
  }
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
  margin-top: 0;
}

ul li + li,
ol li + li {
  margin-top: var(--cipublic-size-s);
}

// TODO: are these right?
dl {
  margin-bottom: 0;
  margin-top: var(--cipublic-size-ml);
}

dt {
  font-weight: var(--cipublic-font-weight-bold);
}

// 1. Undo browser default
dd {
  margin-left: 0; // 1
  margin-top: var(--cipublic-size-s);
}

// Blockquote
blockquote {
  margin: var(--cipublic-size-s) 0 0;
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari
b,
strong {
  font-weight: var(--cipublic-font-weight-bold);
}

// Small
//
// Add the correct font size in all browsers
small {
  @extend %small;
}

// Mark
mark {
  background-color: var(--cipublic-color-tertiary-100);
  padding: var(--cipublic-size-xs);
}

// Sub and Sup
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
sub,
sup {
  font-size: 0.75em; // TODO: css variable? note the em
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

// Links
a {
  @extend %a;
}

// TODO: is this the right way?
.inverted {
  @extend %inverted-a;
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402
// a:not([href]):not([class]) {
//   &,
//   &:hover {
//     color: inherit;
//     text-decoration: none;
//   }
// }
// Tables
//
// Prevent double borders
table {
  border-collapse: collapse;
  caption-side: bottom;
}

caption {
  color: var(--cipublic-color-gray-600);
  padding-bottom: var(--cipublic-size-s);
  padding-top: var(--cipublic-size-s);
  text-align: left;
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari
th {
  font-weight: var(--cipublic-font-weight-bold); // 1
  text-align: inherit; // 2
  text-align: -webkit-match-parent; // 3
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

// Fieldsets/Legends
//
// https://github.com/twbs/bootstrap/blob/main/scss/_reboot.scss#L493
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;

  legend {
    padding: 0;
    width: 100%;
  }
}

// Misc
iframe {
  border: 0;
}

// Hidden attribute - Always hide an element with the `hidden` HTML attribute.
[hidden] {
  display: none !important;
}

// Removed from BS reboot
// Code (pre, code, kbd, samp) - not used on cigna.com
// Figures + Images (figure, img, svg) - prefer components
// Forms + Form elements - prefer components
// Summary - prefer components
// progress - prefer components
// TODO
// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.
// button:focus:not(:focus-visible) {
//   outline: 0;
// }
// TODO: is this a good idea?
// :empty + :where(:last-child),
// :where(:first-child) {
//   margin-top: 0 !important;
// }
// :where(:last-child) {
//   margin-bottom: 0 !important;
// }
// Limelight video and audio player styling overrides. Note that Limelight requires loading js that does not work in shadow dom so this must be included in our reset
cipublic-multimedia {
  display: block;
  position: relative;
  width: 100%;

  &::before {
    --aspect-ratio: calc(9 / 16 * 100%);

    content: '';
    display: block;
    padding-top: var(--aspect-ratio);
  }

  &[variant='audio'] {
    height: 34px !important;

    &::before {
      display: none;
    }
  }

  > * {
    height: 100% !important;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100% !important;
  }

  .vjs-control-bar {
    background-color: var(--cipublic-color-primary-500) !important;
  }
}

.limelight-audio {
  .video-js .vjs-control-bar {
    display: flex !important;
  }

  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1 !important;
  }

  .vjs-limelight-big-play::before {
    content: '' !important;
  }
}

// TODO: is this the right way?
.accent {
  &-small {
    @extend %accent-small;
  }

  &-large {
    @extend %accent-large;
  }
}

.lead-text {
  @extend %lead;
}

.micro-text {
  @extend %micro;
}

.visually-hidden {
  @extend %visually-hidden;
}

.text-align-center {
  text-align: center;
}

.header-no-margin-top {
  margin-top: 0px !important;
}

.no-focus-visible-outline {
  &:focus-visible {
    outline: none;
  }
}
