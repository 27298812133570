@use 'sass:map';
@use 'functions/hsl' as *;
@use './variables' as *;

// All themable variables for the component library must be defined here.
:root {
  // Color tokens
  // ==========================================================================

  // Base Styles
  --cipublic-color-white: #{hsl(#fff)};
  --cipublic-color-transparent: #{hsla(#000, 0)};

  // Grays
  --cipublic-color-gray-100: #{hsl(#f8f8f8)};
  --cipublic-color-gray-300: #{hsl(#e4e4e4)};
  --cipublic-color-gray-400: #{hsl(#d9d9d9)};
  --cipublic-color-gray-500: #{hsl(#939496)};
  --cipublic-color-gray-600: #{hsl(#818283)};
  --cipublic-color-gray-700: #{hsl(#505050)};
  --cipublic-color-gray-900: #{hsl(#333)};

  // Primary/Blues
  --cipublic-color-primary-100: #{hsl(#f4f8fc)};
  --cipublic-color-primary-200: #{hsl(#e6f1f8)};
  --cipublic-color-primary-500: #{hsl(#0170bc)};
  --cipublic-color-primary-700: #{hsl(#004986)};
  --cipublic-color-primary-900: #{hsl(#012d52)};

  // Secondary/Greens
  --cipublic-color-secondary-100: #{hsl(#f2f8ef)};
  --cipublic-color-secondary-800: #{hsl(#118503)};

  // Tertiary/Yellows/Oranges
  --cipublic-color-tertiary-100: #{hsl(#fffaee)};
  --cipublic-color-tertiary-200: #{hsl(#fcf0d6)};
  --cipublic-color-tertiary-500: #{hsl(#f68621)};
  --cipublic-color-tertiary-800: #{hsl(#e35205)};

  // Errors/Reds
  --cipublic-color-error-100: #{hsl(#ffe3e2)};
  --cipublic-color-error-500: #{hsl(#c00)};

  // Misc/Purples
  --cipublic-color-misc-500: #{hsl(#800080)};

  // Site-Level Styles
  --cipublic-background-color: var(--cipublic-color-white);
  --cipublic-text-color: var(--cipublic-color-gray-900);
  --cipublic-background-inverted-color: var(--cipublic-color-gray-900);
  --cipublic-text-inverted-color: var(--cipublic-color-gray-300);
  --cipublic-border-inverted-color: var(--cipublic-color-gray-600);

  // Modal / Components Backdrop
  --cipublic-backdrop: rgb(0 0 0 / 56%);
  --cipublic-inverted-backdrop: rgb(255 255 255 / 70%);

  // Links
  --cipublic-link-color: var(--cipublic-color-primary-500);
  --cipublic-link-border-color: var(--cipublic-color-gray-600);
  --cipublic-link-hover-color: var(--cipublic-color-primary-500);
  --cipublic-link-active-color: var(--cipublic-color-primary-700);
  --cipublic-link-focus-color: var(--cipublic-color-primary-900);
  --cipublic-link-visited-color: var(--cipublic-color-misc-500);
  --cipublic-link-visited-hover-color: var(--cipublic-color-primary-700);
  --cipublic-link-visited-border-color: var(--cipublic-color-gray-600);

  // Typography tokens
  // ==========================================================================

  // Fonts
  --cipublic-font-primary: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --cipublic-font-secondary: 'Verdana', 'Geneva', sans-serif;
  --cipublic-font-tertiary: Merriweather, Georgia, serif;

  // Font weights
  --cipublic-font-weight-normal: 400; // open sans regular (lead, body, micro, accents)
  --cipublic-font-weight-medium: 500; // gotham (h2-h4, buttons)
  --cipublic-font-weight-semibold: 600; // open sans (links)
  --cipublic-font-weight-bold: 700; // open sans (bold), merriweather (h1)

  // Font sizes
  --cipublic-font-size-xxs: 0.75rem; // 12px
  --cipublic-font-size-xs: 0.875rem; // Small Button Text
  --cipublic-font-size-s: 1rem; // Accent Small, Small, Micro
  --cipublic-font-size-m: 1.125rem; // Body
  --cipublic-font-size-ml: 1.25rem; // Lead, Accent Large
  --cipublic-font-size-l: 1.5rem; // H4
  --cipublic-font-size-mxl: 1.75rem;
  --cipublic-font-size-xl: 2rem; // H3
  --cipublic-font-size-xxl: 3rem; // H2
  --cipublic-font-size-xxxl: 4.25rem; // H1

  // Line heights
  // what? line-height should be unitless, specs are usually provided as font-size: 1.75rem, line-height: 2rem
  // converted to unitless line-height: (2 / 1.75) or 1.14285714285714
  --cipublic-line-height-xs: 1.11764705; // h1
  --cipublic-line-height-ms: 1.28571428;
  --cipublic-line-height-s: 1.3333333333; // h2, h4
  --cipublic-line-height-m: 1.5; // h3
  --cipublic-line-height-ml: 1.6; // h5, h6
  --cipublic-line-height-mxl: 1.7142857; // micro, accent-small (mobile)
  --cipublic-line-height-l: 1.75; // micro, accent-small
  --cipublic-line-height-xl: 1.7777777778; // body
  --cipublic-line-height-xxl: 1.8; // lead paragraph, accent-large

  // Letter spacing
  // Sizing & Spacing tokens *****
  // ==========================================================================
  --cipublic-size-xxs: 0.25rem; // 4px
  --cipublic-size-xs: 0.5rem; // 8px
  --cipublic-size-s: 1rem; // 16px
  --cipublic-size-m: 1.5rem; // 24px
  --cipublic-size-ml: 2rem; // 32px
  --cipublic-size-l: 3rem; // 48px
  --cipublic-size-xl: 4rem; // 64px
  --cipublic-size-xxl: 6rem; // 96px
  --cipublic-size-xxxl: 8rem; // 128px

  // Border tokens
  --cipublic-border-size-s: 0.0625rem; // 1px
  --cipublic-border-size-m: 0.125rem; // 2px
  --cipublic-border-size-l: var(--cipublic-size-xxs); // 4px

  // Border Radius tokens
  // ==========================================================================
  --cipublic-border-radius-xs: 0.063rem; // 1px
  --cipublic-border-radius-s: 0.188rem; //3px
  --cipublic-border-radius-m: 0.5rem; // 8px
  --cipublic-border-radius-l: 0.625rem; // 10px
  --cipublic-border-radius-button: 1.5rem; // 24px
  --cipublic-border-radius-circle: 50%; // Useful?

  // Elevation
  // ==========================================================================
  --cipublic-shadow-inset-20: inset 1px 1px 4px 0 rgb(0 0 0 / 20%);
  --cipublic-shadow-low-20: 1px 1px 4px 0 rgb(0 0 0 / 20%);
  --cipublic-shadow-low-25: 1px 1px 4px 0 rgb(0 0 0 / 25%);
  --cipublic-shadow-low-30: 1px 1px 4px 0 rgb(0 0 0 / 30%);
  --cipublic-shadow-low-40: 1px 1px 4px 0 rgb(0 0 0 / 40%);
  --cipublic-shadow-high-10: 0 2px 8px 0 rgb(0 0 0 / 10%);
  --cipublic-shadow-high-20: 0 2px 8px 0 rgb(0 0 0 / 20%);
  --cipublic-shadow-floating-30: 0 12px 30px 0 rgb(0 0 0 / 30%);

  // Transition (slow, medium, fast in ms)
  // ==========================================================================

  // duration
  --cipublic-duration-xxs: 0.1s;
  --cipublic-duration-xs: 0.2s;
  --cipublic-duration-s: 0.3s;
  --cipublic-duration-m: 0.4s;
  --cipublic-duration-l: 0.5s;
  --cipublic-duration-xl: 0.6s;
  --cipublic-duration-xxl: 0.7s;

  // Z-index
  // ==========================================================================
  --cipublic-zindex-300: 300;
  --cipublic-zindex-200: 200;
  --cipublic-zindex-100: 100;
  --cipublic-zindex-50: 50;
  --cipublic-zindex-25: 25;
  --cipublic-zindex-10: 10;

  // Grid/Layout
  // ==========================================================================
  --cipublic-grid-gap: var(--cipublic-size-m);

  // Breakpoints
  @each $key, $value in $grid-breakpoints {
    --cipublic-breakpoint-#{$key}: #{$value};
  }

  // Containers
  @each $key, $value in $container-max-widths {
    --cipublic-container-max-width-#{$key}: #{$value};
  }

  // Forms
  // ==========================================================================

  // widths
  --cipublic-form-width-xxs: 100px;
  --cipublic-form-width-xs: 140px;
  --cipublic-form-width-s: 180px;
  --cipublic-form-width-ms: 220px;
  --cipublic-form-width-m: 260px;
  --cipublic-form-width-ml: 300px;
  --cipublic-form-width-l: 340px;
  --cipublic-form-width-xl: 380px;
  --cipublic-form-width-xxl: 420px;
  --cipublic-form-width-full: 100%;

  // Anchor scroll offset
  --sticky-scroll-margin: 0;
}