@use 'mixins/font-face' as *;

$font-path: '../' !default;

@include font-face('Open Sans', '#{$font-path}open-sans-v18-latin-regular', 400, normal, woff2 woff);
@include font-face('Open Sans', '#{$font-path}open-sans-v18-latin-italic', 400, italic, woff2 woff);
@include font-face('Open Sans', '#{$font-path}open-sans-v18-latin-600', 600, normal, woff2 woff);
@include font-face('Open Sans', '#{$font-path}open-sans-v18-latin-600italic', 600, italic, woff2 woff);
@include font-face('Open Sans', '#{$font-path}open-sans-v18-latin-700', 700, normal, woff2 woff);
@include font-face('Open Sans', '#{$font-path}open-sans-v18-latin-700italic', 700, italic, woff2 woff);
@include font-face('Merriweather', '#{$font-path}merriweather-v28-latin-regular', 400, normal, woff2 woff);
@include font-face('Merriweather', '#{$font-path}merriweather-v28-latin-italic', 400, italic, woff2 woff);
@include font-face('Merriweather', '#{$font-path}merriweather-v22-latin-700', 700, normal, woff2 woff);
@include font-face('Merriweather', '#{$font-path}merriweather-v22-latin-700italic', 700, italic, woff2 woff);