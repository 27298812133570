@use 'sass:map';
@use 'mixins/breakpoints' as *;
@use 'mixins/grid' as *;
@use 'placeholders';

.cipublic-container {
  @extend %cipublic-container;
}

.container {
  &-12 {
    @extend %cipublic-sub-container;
    grid-template-columns: minmax(0, 1fr);
  }

  &-4-1-7 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        4,
        1,
        7
      )
    );
  }

  &-5-1-6 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        5,
        1,
        6
      )
    );
  }

  &-7-1-4 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        7,
        1,
        4
      )
    );
  }

  &-5-7 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        5,
        7
      )
    );
  }

  &-7-5 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        7,
        5
      )
    );
  }

  &-6-6 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        6,
        6
      )
    );
  }

  &-6-1-5 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        6,
        1,
        5
      )
    );
  }

  &-8-1-3 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        8,
        1,
        3
      )
    );
  }

  &-4-4-4 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        4,
        4,
        4
      )
    );
  }

  &-2-8-2 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        2,
        8,
        2
      )
    );
  }

  &-3-2-2-2-3 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        3,
        2,
        2,
        2,
        3
      )
    );
  }

  &-3-2-4-3 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        3,
        2,
        4,
        3
      )
    );
  }

  &-3-3-3-3 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        3,
        3,
        3,
        3
      )
    );
  }

  &-1-10-1 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        1,
        10,
        1
      )
    );
  }

  &-3-6-3 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        3,
        6,
        3
      )
    );
  }

  &-8-4 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        8,
        4
      )
    );
  }

  &-4-8 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        4,
        8
      )
    );
  }

  &-9-3 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        9,
        3
      )
    );
  }

  &-3-9 {
    @extend %cipublic-sub-container;
    @include create-container(
      $columns: (
        3,
        9
      )
    );
  }

  &-7-1-4,
  &-4-1-7,
  &-5-7,
  &-7-5,
  &-6-6 {
    @include media-breakpoint-down(md) {
      grid-template-areas:
        'row-1'
        'row-2';

      &.container-inverted {
        /* stylelint-disable-next-line max-nesting-depth */
        > :nth-child(1) {
          grid-area: row-2;
        }
      }
    }
  }

  &-full-width {
    @extend %full-bleed;
  }
}

[has-grid-background] {
  gap: var(--cipublic-grid-gap);
  margin-top: var(--cipublic-size-xl);

  [grid-background],
  &.container-full-width {
    background-color: var(--grid-background-color);
    border-radius: var(--cipublic-border-radius-l);
    padding: var(--cipublic-size-xl);

    @include media-breakpoint-down(md) {
      padding: var(--cipublic-size-l);
    }

    &[grid-background='primary'] {
      --grid-background-color: var(--cipublic-color-primary-100);
    }

    &[grid-background='primary-200'] {
      --grid-background-color: var(--cipublic-color-primary-200);
    }

    &[grid-background='secondary'] {
      --grid-background-color: var(--cipublic-color-secondary-100);
    }

    &[grid-background='tertiary'] {
      --grid-background-color: var(--cipublic-color-tertiary-100);
    }

    &[grid-background='alternate'] {
      --grid-background-color: var(--cipublic-color-gray-100);
    }

    &[background-size='small'] {
      padding: var(--cipublic-size-l);
    }

    > *:first-child {
      margin-top: 0 !important;
    }

    [has-grid-background-container]:first-child {
      @include media-breakpoint-down(sm) {
        > div:first-child {
          > *:first-child {
            margin-top: 0 !important;
          }
        }
      }
      @include media-breakpoint-up(md) {
        > div {
          > *:first-child {
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  [grid-background][grid-fixed-height] {
    height: fit-content;
  }

  &.container-full-width {
    border-radius: 0;
    padding: 0 !important;

    [grid-background] {
      @extend %cipublic-container;
      background-color: var(--cipublic-color-transparent) !important;
    }
  }

  &[class*='container-'] {
    > div {
      > *:first-child {
        margin-top: 0 !important;
      }
    }
  }
}
