@use 'sass:map';
@use 'sass:math';
@use 'mixins/breakpoints' as *;
@use 'variables' as *;

/* stylelint-disable scss/percent-placeholder-pattern */
// Placeholders don't generate any css until extended using @extend

%h1 {
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-tertiary);
  font-size: var(--cipublic-font-size-xl);
  font-weight: var(--cipublic-font-weight-bold);
  line-height: var(--cipublic-line-height-m);
  margin-bottom: var(--cipublic-size-s);
  margin-top: var(--cipublic-size-xl);

  @include media-breakpoint-up(ms) {
    font-size: var(--cipublic-font-size-xxl);
    line-height: var(--cipublic-line-height-s);
  }

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-xxxl);
    line-height: var(--cipublic-line-height-xs);
  }

  &-important {
    color: var(--cipublic-text-color) !important;
    font-family: var(--cipublic-font-tertiary) !important;
    font-size: var(--cipublic-font-size-xl) !important;
    font-weight: var(--cipublic-font-weight-bold) !important;
    line-height: var(--cipublic-line-height-m) !important;
    margin-bottom: var(--cipublic-size-s) !important;
    margin-top: var(--cipublic-size-xl) !important;

    @include media-breakpoint-up(ms) {
      font-size: var(--cipublic-font-size-xxl) !important;
      line-height: var(--cipublic-line-height-s) !important;
    }

    @include media-breakpoint-up(md) {
      font-size: var(--cipublic-font-size-xxxl) !important;
      line-height: var(--cipublic-line-height-xs) !important;
    }
  }
}

%h2 {
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-tertiary);
  font-size: var(--cipublic-font-size-mxl);
  font-weight: var(--cipublic-font-weight-bold);
  line-height: var(--cipublic-line-height-ms);
  margin-bottom: var(--cipublic-size-s);
  margin-top: var(--cipublic-size-xl);

  @include media-breakpoint-up(ms) {
    font-size: var(--cipublic-font-size-xl);
    line-height: var(--cipublic-line-height-m);
  }

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-xxl);
    line-height: var(--cipublic-line-height-s);
  }

  &-important {
    color: var(--cipublic-text-color) !important;
    font-family: var(--cipublic-font-tertiary) !important;
    font-size: var(--cipublic-font-size-mxl) !important;
    font-weight: var(--cipublic-font-weight-bold) !important;
    line-height: var(--cipublic-line-height-ms) !important;
    margin-bottom: var(--cipublic-size-s) !important;
    margin-top: var(--cipublic-size-xl) !important;

    @include media-breakpoint-up(ms) {
      font-size: var(--cipublic-font-size-xl) !important;
      line-height: var(--cipublic-line-height-m) !important;
    }

    @include media-breakpoint-up(md) {
      font-size: var(--cipublic-font-size-xxl) !important;
      line-height: var(--cipublic-line-height-s) !important;
    }
  }
}

%h3 {
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-secondary);
  font-size: var(--cipublic-font-size-l);
  font-weight: var(--cipublic-font-weight-medium);
  line-height: var(--cipublic-line-height-s);
  margin-bottom: var(--cipublic-size-s);
  margin-top: var(--cipublic-size-l);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-xl);
    line-height: var(--cipublic-line-height-m);
  }

  &-important {
    color: var(--cipublic-text-color) !important;
    font-family: var(--cipublic-font-secondary) !important;
    font-size: var(--cipublic-font-size-l) !important;
    font-weight: var(--cipublic-font-weight-medium) !important;
    line-height: var(--cipublic-line-height-s) !important;
    margin-bottom: var(--cipublic-size-s) !important;
    margin-top: var(--cipublic-size-l) !important;

    @include media-breakpoint-up(md) {
      font-size: var(--cipublic-font-size-xl) !important;
      line-height: var(--cipublic-line-height-m) !important;
    }
  }
}

%h4 {
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-secondary);
  font-size: var(--cipublic-font-size-ml);
  font-weight: var(--cipublic-font-weight-medium);
  line-height: var(--cipublic-line-height-ml);
  margin-bottom: var(--cipublic-size-s);
  margin-top: var(--cipublic-size-ml);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-l);
    line-height: var(--cipublic-line-height-s);
  }

  &-important {
    color: var(--cipublic-text-color) !important;
    font-family: var(--cipublic-font-secondary) !important;
    font-size: var(--cipublic-font-size-ml) !important;
    font-weight: var(--cipublic-font-weight-medium) !important;
    line-height: var(--cipublic-line-height-ml) !important;
    margin-bottom: var(--cipublic-size-s) !important;
    margin-top: var(--cipublic-size-ml) !important;

    @include media-breakpoint-up(md) {
      font-size: var(--cipublic-font-size-l) !important;
      line-height: var(--cipublic-line-height-s) !important;
    }
  }
}

%h5 {
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-secondary);
  font-size: var(--cipublic-font-size-m);
  font-weight: var(--cipublic-font-weight-normal);
  line-height: var(--cipublic-line-height-xl);
  margin-bottom: var(--cipublic-size-s);
  margin-top: var(--cipublic-size-m);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-ml);
    line-height: var(--cipublic-line-height-ml);
  }

  &-important {
    color: var(--cipublic-text-color) !important;
    font-family: var(--cipublic-font-secondary) !important;
    font-size: var(--cipublic-font-size-m) !important;
    font-weight: var(--cipublic-font-weight-normal) !important;
    line-height: var(--cipublic-line-height-xl) !important;
    margin-bottom: var(--cipublic-size-s) !important;
    margin-top: var(--cipublic-size-m) !important;

    @include media-breakpoint-up(md) {
      font-size: var(--cipublic-font-size-ml) !important;
      line-height: var(--cipublic-line-height-ml) !important;
    }
  }
}

%h6 {
  color: var(--cipublic-text-color);
  font-family: var(--cipublic-font-secondary);
  font-size: var(--cipublic-font-size-m);
  font-weight: var(--cipublic-font-weight-medium);
  line-height: var(--cipublic-line-height-ml);
  margin-bottom: var(--cipublic-size-s);
  margin-top: var(--cipublic-size-m);

  &-important {
    color: var(--cipublic-text-color) !important;
    font-family: var(--cipublic-font-secondary) !important;
    font-size: var(--cipublic-font-size-m) !important;
    font-weight: var(--cipublic-font-weight-medium) !important;
    line-height: var(--cipublic-line-height-ml) !important;
    margin-bottom: var(--cipublic-size-s) !important;
    margin-top: var(--cipublic-size-m) !important;
  }
}

%p {
  font-family: var(--cipublic-font-primary);
  font-size: var(--cipublic-font-size-s);
  line-height: var(--cipublic-line-height-l);
  margin-top: var(--cipublic-size-ml);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-m);
    line-height: var(--cipublic-line-height-xl);
  }

  &-important {
    font-family: var(--cipublic-font-primary) !important;
    font-size: var(--cipublic-font-size-s) !important;
    line-height: var(--cipublic-line-height-l) !important;
    margin-top: var(--cipublic-size-ml) !important;

    @include media-breakpoint-up(md) {
      font-size: var(--cipublic-font-size-m) !important;
      line-height: var(--cipublic-line-height-xl) !important;
    }
  }
}

%span {
  // for iOS
  // https://stackoverflow.com/questions/1556363/weird-span-element-rendering-in-safari-on-iphone
  text-size-adjust: none;
}

%small {
  font-size: var(--cipublic-font-size-xs);
  line-height: var(--cipublic-line-height-l);
}

// TODO: how do we use these?, helper classes?

%lead {
  font-family: var(--cipublic-font-primary);
  font-size: var(--cipublic-font-size-m);
  line-height: var(--cipublic-line-height-xl);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-ml);
    line-height: var(--cipublic-line-height-xxl);
  }
}

%micro {
  font-family: var(--cipublic-font-primary);
  font-size: var(--cipublic-font-size-xs);
  line-height: var(--cipublic-line-height-mxl);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-s);
    line-height: var(--cipublic-line-height-l);
  }
}

%accent {
  font-family: var(--cipublic-font-primary);
  font-size: var(--cipublic-font-size-m);
  font-style: italic;
  font-weight: var(--cipublic-font-weight-medium);
}

%accent-small {
  @extend %accent;
  font-size: var(--cipublic-font-size-xs);
  line-height: var(--cipublic-line-height-mxl);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-s);
    line-height: var(--cipublic-line-height-l);
  }
}

%accent-large {
  @extend %accent;
  font-size: var(--cipublic-font-size-m);
  line-height: var(--cipublic-line-height-xl);

  @include media-breakpoint-up(md) {
    font-size: var(--cipublic-font-size-ml);
    line-height: var(--cipublic-line-height-xxl);
  }
}

%fieldset-reset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

%legend-reset {
  padding: 0;
  width: 100%;
}

%check-radio-reset {
  // remove most all native input styles
  appearance: none;
  // for iOS < 15 to remove gradient background
  background-color: var(--cipublic-color-white);
  // reset margin on Safari
  margin: 0;

  &:focus {
    // remove the default blue focus border in Safari
    outline: none;
  }
}

%input-reset {
  background-color: var(--cipublic-color-white);
  font-family: inherit;
  font-size: var(--cipublic-border-size-s);
  // compute to at least 16px to avoid zooming being triggered upon interaction in mobile Safari.
  font-size: max(var(--cipublic-border-size-s), 1em);
  // reset margin on Safari
  margin: 0;

  &:focus {
    // remove the default blue focus border in Safari
    outline: none;
  }
}

%search-reset {
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
}

%textarea-reset {
  background-color: var(--cipublic-color-white);
  font-family: inherit;
  font-size: var(--cipublic-border-size-s);
  // compute to at least 16px to avoid zooming being triggered upon interaction in mobile Safari
  font-size: max(var(--cipublic-border-size-s), 1em);
  // reset margin on Safari
  margin: 0;
  // limit to vertical resizing only to prevent layout breakage by user
  resize: vertical;

  &:focus {
    // remove the default blue focus border in Safari
    outline: none;
  }
}

%select-reset {
  appearance: none;
  background-color: var(--cipublic-color-transparent);
  border: 0;
  cursor: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  // reset margin on Safari
  margin: 0;
  // remove focus outline
  outline: none;
  padding: 0 1em 0 0;
  width: 100%;
}

%list-parent-unstyled {
  list-style: none;
  margin: 0 auto;
  padding-left: 0;
}

%list-unstyled {
  list-style: none;
  padding-left: 0;

  > li::marker {
    content: '';
    display: none;
  }
}

%list-inline {
  li::marker {
    content: '';
    display: none;
  }
}

%a {
  background: none;
  border: 0;
  border-bottom: 1px solid var(--cipublic-link-border-color);
  color: var(--cipublic-link-color);
  cursor: pointer;
  font-weight: var(--cipublic-font-weight-semibold);
  line-height: var(--cipublic-line-height-ml);
  padding-bottom: 1px;
  text-decoration: none;

  &:visited {
    border-color: var(--cipublic-link-visited-border-color);
    color: var(--cipublic-link-visited-color);
  }

  &:focus {
    background: var(--cipublic-link-focus-color);
    border-color: var(--cipublic-link-focus-color);
    color: var(--cipublic-color-white);
    outline: 1px solid var(--cipublic-link-focus-color);
    outline-offset: initial;

    &:hover {
      border-color: var(--cipublic-link-focus-color);
      color: var(--cipublic-color-white);
    }

    &:active {
      background: none;
      border-color: var(--cipublic-link-active-color);
      color: var(--cipublic-link-active-color);
      outline: none;
    }
  }

  &:hover {
    border-color: var(--cipublic-link-hover-color);
    color: var(--cipublic-link-hover-color);
  }

  &:active {
    border-color: var(--cipublic-link-active-color);
    color: var(--cipublic-link-active-color);
  }
}

%inverted-a {
  --cipublic-link-color: var(--cipublic-text-inverted-color);
  --cipublic-link-border-color: var(--cipublic-border-inverted-color);
  --cipublic-link-focus-color: var(--cipublic-text-inverted-color);
  --cipublic-link-hover-color: var(--cipublic-text-inverted-color);
  --cipublic-link-active-color: var(--cipublic-text-inverted-color);
  --cipublic-link-visited-color: var(--cipublic-text-inverted-color);
  --cipublic-link-visited-hover-color: var(--cipublic-text-inverted-color);
  --cipublic-link-visited-border-color: var(--cipublic-border-inverted-color);
  background-color: var(--cipublic-background-inverted-color);
  color: var(--cipublic-text-inverted-color);

  a {
    &:focus {
      background: none;
      outline: 0;
    }

    &:focus-visible {
      outline: var(--cipublic-color-primary-500) auto 1px;
    }
  }
}

%break-word {
  word-break: break-word;
}

%full-bleed {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

%cipublic-container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--cipublic-size-s);
  padding-right: var(--cipublic-size-s);
  width: 100%;

  @each $breakpoint in map.keys($grid-breakpoints) {
    $max-width: map.get($container-max-widths, $breakpoint);
    @if $max-width {
      @include media-breakpoint-up($breakpoint) {
        max-width: $max-width;
      }
    }
  }
}

%cipublic-sub-container {
  display: grid;
}

%not-defined {
  &:not(:defined) {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    * {
      visibility: hidden;
    }
  }
}

/*
  Hiding class, making content visible only to screen readers but not visually ("sr" meaning "screen-reader")
  Is the hidden content read by a screen reader?	- Yes
  Will the hidden element affect the document layout? - No
  Will the hidden element’s box model be rendered? - No
  Does the element detect clicks or focus? - Yes
  https://css-tricks.com/comparing-various-ways-to-hide-things-in-css/#method-5-the-visually-hidden-class
*/
%visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* stylelint-enable scss/percent-placeholder-pattern */
