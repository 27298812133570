@use 'sass:map';
@use 'sass:list';
@use 'sass:math';
@use './breakpoints' as *;

@function create-columns($columns, $gutters) {
  $columns-created: ();
  @each $column in $columns {
    @if $gutters == 0 {
      $columns-created: list.append($columns-created, minmax(0, calc(((100% / 12) * #{$column}))));
    } @else {
      $columns-created: list.append($columns-created, minmax(0, calc(((100% / 12) * #{$column}) - #{$gutters}#{px})));
    }
  }
  @return $columns-created;
}

@mixin create-container($columns, $gap: var(--cipublic-grid-gap), $gutters: null, $breakpoint: md) {
  @if $gutters == null {
    $gutters: math.div(24, list.length($columns)) * (list.length($columns) - 1);
  }

  @include media-breakpoint-up($breakpoint) {
    grid-gap: #{$gap};
    grid-template-columns: create-columns($columns, $gutters);
  }
}

//TODO: How to use? A wrapper inside the web component or make a subcontainer?
@mixin create-smart-container($min-col-size: 3, $flow: column, $gap: var(--cipublic-grid-gap), $gutters: null, $breakpoint: md) {
  @if $gutters == null {
    $gap-count: math.div(12, $min-col-size) - 1;
    $gap-fill: 24 * $gap-count;
    $total-cols: math.div(12, $min-col-size);
    $gutters: math.div($gap-fill, $total-cols);
  }

  @include media-breakpoint-up($breakpoint) {
    display: grid;
    grid-gap: #{$gap};
    @if $flow == column {
      grid-template-columns: repeat(auto-fit, minmax(calc(((100% / 12) * #{$min-col-size}) - #{$gutters}#{px}), 1fr));
    } @else {
      grid-auto-flow: row;
    }
  }
}
